import analiticaInstance from '../../plugins/analitica.config'

function translateItem (item) {
  switch (item) {
    case 'configuration':
      return 'settings'
    case 'financial':
      return 'finance'
    case 'helpCenter':
      return 'help center'
    case 'home':
      return 'resume'
    case 'recipients':
      return 'recipients'
    case 'recurrence':
      return 'recurrence'
    case 'sales':
      return 'sales'
    case 'team':
      return 'team'
    default:
      return ''
  }
}

function eventDescription (item) {
  switch (item) {
    case 'settings':
      return 'Evento a ser rastreado quando o usuário clicar em alguma seção de Configurações na home da dash Pilot'
    case 'finance':
      return 'Evento a ser rastreado quando o usuário clicar em alguma seção de Financeiro na home da dash Pilot'
    case 'help center':
      return 'Evento a ser rastreado quando o usuário clicar em Central de ajuda na home da dash Pilot'
    case 'resume':
      return 'Evento a ser rastreado quando o usuário clicar em resume na home da dash Pilot'
    case 'recipients':
      return 'Evento a ser rastreado quando o usuário clicar Recebedores na home da dash Pilot'
    case 'recurrence':
      return 'Evento a ser rastreado quando o usuário clicar em alguma seção de Recorrência na home da dash Pilot'
    case 'sales':
      return 'Evento a ser rastreado quando o usuário clicar em alguma seção de Vendas na home da dash Pilot'
    case 'team':
      return 'Evento a ser rastreado quando o usuário clicar Equipes na home da dash Pilot'
    default:
      return ''
  }
}

export default analiticaInstance.eventFactory('Pilot Home', {
  sidebar: {
    /**
    * @analitica Event to be tracked when the user clicks on an item in the sidebar
    */
    menuItemClick: (item, option) => ({
      label: `pilot ${translateItem(item)} - bottom clicked`,
      metadata: {
        event: eventDescription(translateItem(item)),
        option,
      },
    }),
  },
})
